/* eslint-disable no-undef */
import React, { Component } from "react";

export default function withWindowDimensions(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = { width: 0, height: 0 };
    }

    componentDidMount() {
      this.updateWindowDimensions();
      window.addEventListener("resize", this.updateWindowDimensions);
    }

    componentWillUnmount() {
      window.removeEventListener("resize", this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
      this.setState({ width: window.innerWidth, height: window.innerHeight });
    };

    render() {
      const { height, width } = this.state;
      return (
        <WrappedComponent
          {...this.props}
          windowWidth={width}
          windowHeight={height}
          isMobileSized={width > 0 && width < 768}
        />
      );
    }
  };
}
